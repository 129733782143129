/* You can add global styles to this file, and also import other style files */

@use "@angular/material" as mat;

@use "variables";
@use "mixins";
@use "material_override";

@import "bootstrap/dist/css/bootstrap-reboot.min.css";
@import "bootstrap/dist/css/bootstrap-grid.min.css";

@import "@angular/material/prebuilt-themes/indigo-pink.css";

$seg-theme: mat.m2-define-light-theme(
    (
      color: (
        primary: mat.m2-define-palette(mat.$m2-amber-palette),
        accent: mat.m2-define-palette(mat.$m2-pink-palette)
      ),
    )
);

@include mat.all-component-themes($seg-theme);

a, a:hover {
  cursor: pointer;
  text-decoration: none;
  color: inherit;
  transition: all .2s ease-in-out;
}

@font-face {
  font-family: SomfySansMedium;
  src: url("assets/font/SomfySans-Medium.otf") format("opentype");
}

@font-face {
  font-family: SomfySansBold;
  src: url("assets/font/SomfySans-Bold.otf") format("opentype");
}

@font-face {
  font-family: SomfySansRegular;
  src: url("assets/font/SomfySans-Regular.otf") format("opentype");
}

/*@font-face {
  font-family: RoundProBold;
  src: url("assets/font/FontFont - DINRoundPro-Bold.otf") format("opentype");
}*/

@font-face {
  font-family: SomfySansLightItalic;
  src: url("assets/font/SomfySans-LightItalic.otf") format("opentype");
}

@font-face {
  font-family: SomfySansLight;
  src: url("assets/font/SomfySans-Light.otf") format("opentype");
}

/* everywhere !important pour surcharger les styles des classes d'éléments générés*/
p {
  font-family: SomfySansLight;
}

html {
  background-color: white;
}

body {
  color: variables.$blue-grey;
  background-color: variables.$pearl;
  font-family: SomfySansRegular;
}

.modal-open {
  overflow: hidden !important;
}

/*
.bold {
  font-family: RoundProBold;
}
*/

.regular {
  font-family: SomfySansRegular;
}

.medium {
  font-family: SomfySansMedium;
}

/*
.bold-second {
  font-family: SomfySansBold;
}

.light {
  font-family: CondProLight;
}
*/

.uppercase {
  text-transform: uppercase;
}

ul, ol {
  list-style: none;
  padding: 0;
  margin: 0;
}

input[type=password]:focus:not([readonly]), input[type=email]:focus:not([readonly]), input[type=url]:focus:not([readonly]), input[type=time]:focus:not([readonly]), input[type=date]:focus:not([readonly]), input[type=datetime-local]:focus:not([readonly]), input[type=tel]:focus:not([readonly]), input[type=number]:focus:not([readonly]), input[type=search-md]:focus:not([readonly]), input[type=search]:focus:not([readonly]),
textarea.md-textarea:focus:not([readonly]) {
  border: none;
}

input[type=password]:not([readonly]), input[type=email]:not([readonly]), input[type=url]:not([readonly]), input[type=time]:not([readonly]), input[type=date]:not([readonly]), input[type=datetime-local]:not([readonly]), input[type=tel]:not([readonly]), input[type=number]:not([readonly]), input[type=search-md]:not([readonly]), input[type=search]:not([readonly]),
textarea.md-textarea:not([readonly]) {
  border: none;
}

.cursor-pointer {
  cursor: pointer !important;
}

p {
  margin: 0;
}


//-------------------------------------------------------------------------------------------------

// backlink used in several places
.servego-breadcrumb {
  @include mixins.flex-box-space-between();
  @include mixins.flex-box-vertical-align-center();
  width: 100%;
  line-height: 21px;
  color: variables.$blue-grey !important;
  font-family: SomfySansLight;
  font-size: 14px;

  .back-arrow {
    font-family: SomfySansMedium;
    font-weight: bold;
  }

  .text {
    text-decoration: underline;
  }

  a {
    color: variables.$blue-grey !important;
  }
}

div:focus {
  outline: none;
}

/* Tooltips */
.advice-tooltip, .notice-tooltip {
  &.mat-mdc-tooltip .mdc-tooltip__surface {
    border: solid 1px variables.$very-light-pink;
    background-color: variables.$pearl;
    color: variables.$blue;
    font-family: SomfySansLight;
    font-weight: bold;
    font-size: 1em;
    padding: 10px;
  }
}


.mat-calendar-body-label {
  opacity: 0;
}

.mat-calendar-body-label[colspan="7"] {
  display: none;
}

.right-menu-wrapper {
  .right-menu-list {
    .languages {
      margin: 0;
      padding-left: 1vw;
      padding-top: 0.5vh;

      .mat-mdc-select-value {
        color: white !important;
        font-size: 14px;
        font-family: SomfySansLight;
      }
    }
  }
}

.menu-panel {
  width: 180px;

  .mat-mdc-menu-content {
    app-alert-link,
    app-alert-delete-button {
      display: inline-block;
      width: 100%;
      min-height: 35px;
      color: variables.$blue;
      font-family: SomfySansRegular, sans-serif;
    }

    app-alert-delete-button:before {
      content: '';
      display: block;
      width: 85%;
      border-top: 1px solid variables.$light-grey;
      margin: 0 auto;
    }

    app-alert-link {
      a {
        padding: 10px 16px;
      }
    }

    app-alert-delete-button {
      button {
        display: flex;
        padding: 10px 16px;
        color: variables.$light-red;
      }
    }

    .alert-resolution-link {
      display: flex;
      align-items: center;
      min-height: 35px;
      padding: 10px 16px;
      font-family: SomfySansRegular, sans-serif;
    }
  }
}

app-alert-resolution {
  .mat-mdc-progress-bar {
    transform: scaleY(1.5);
    overflow: hidden;
  }

  .mdc-linear-progress__buffer-bar {
    background-color: variables.$dark-pearl;
  }
}

.remote-selected-channel-wrapper {
  mat-form-field {
    .mdc-text-field--filled:not(.mdc-text-field--disabled) {
      background-color: white;
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.2);

      .mdc-line-ripple::before {
        border: none;
      }
    }
  }

  .mat-pseudo-checkbox-checked.mat-pseudo-checkbox-minimal::after {
    opacity: 1;
  }
}

app-alert-notification-toggle {
  .mat-mdc-slide-toggle .mdc-switch {
    width: 40px !important;
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__track {
    height: 20px !important;
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle {
    height: 18px !important;
    width: 18px !important;
  }

  .mat-mdc-slide-toggle .mdc-switch .mdc-switch__handle-track {
    width: calc(100% - 20px) !important;
  }
}

app-alert-mailing-frequency-selector {
  .mat-mdc-form-field {
    margin-left: 10px;
  }

  .mdc-text-field--filled {
    background-color: variables.$white !important;
    border: solid 1px variables.$blue-grey;
    border-radius: 0;
  }

  .mdc-text-field--filled.mdc-text-field--disabled {
    border-color: variables.$light-grey;
  }

  .mat-mdc-form-field-infix {
    padding: 10px 0 !important;
  }

  .mdc-text-field {
    padding: 0 !important;
  }

  .mat-mdc-select-min-line {
    width: auto;
    font-family: SomfySansRegular, sans-serif;
    font-size: 14px;
    color: variables.$blue-grey
  }

  .mat-mdc-select-trigger {
    justify-content: space-evenly;
  }

  .mat-mdc-select-value {
    width: auto;
  }

  .mdc-line-ripple::before,
  .mdc-text-field--filled.mdc-text-field--disabled .mdc-line-ripple::before {
    border-bottom: 0
  }

  .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

app-tab-navigation {
  .mat-mdc-tab-links {
    gap: 8px;
  }

  .mat-mdc-tab-header.mat-mdc-tab-nav-bar-stretch-tabs .mat-mdc-tab-link {
    flex-grow: 0;
  }

  .mdc-tab {
    padding-left: 8px;
    padding-right: 8px;
  }

  .mdc-tab-indicator__content--underline {
    border-top-width: 3px !important;
    border-radius: 2px !important;
  }
}

.list-item {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 7px;
    right: 7px;
    height: 1px;
    background-color: variables.$dark-pearl;
  }
}

app-gateways-list .mat-mdc-list app-site-icon .gateway-icon {
  height: 60px;
}


.video-call-modal-backdrop {
  &.minimized {
    display: none;
  }
}

.video-call-modal {
  .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
    background-color: variables.$dark-smart-blue;
    border-radius: 16px;
  }

  .mdc-dialog__container {
    padding: 16px;
  }

  &.minimized {
    .mat-mdc-dialog-surface {
      box-shadow: 0 4px 6px -4px #0000001A, 0 10px 15px -3px #0000001A;
    }

    .mdc-dialog__container, .mat-mdc-dialog-container .mdc-dialog__surface {
      background-color: transparent;
      border-radius: 7px;
    }

    .mdc-dialog__container {
      padding: 0;
    }
  }
}
